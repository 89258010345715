import { useCallback, useEffect, useState } from 'react';
import getIntercom from '@/services/intercom';

const CHAT_INIT_DONE_LS_KEY = 'intercom-chat-init-done';

export const useInitChat = ({
  message,
  slug,
}: {
  message?: string;
  slug?: string;
}) => {
  const LS_KEY = `${CHAT_INIT_DONE_LS_KEY}-${slug || ''}`;

  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    setInitDone(!!localStorage?.getItem(LS_KEY));
  }, [LS_KEY]);

  return useCallback(async () => {
    // TODO rebuild with intercom API ?

    if (initDone) {
      getIntercom()?.('show');
      return;
    }

    getIntercom()?.('showNewMessage', message);

    localStorage.setItem(LS_KEY, '1');
    setInitDone(true);

    if (!message) return;

    let retriesCount = 1;
    const postMessage = () => {
      const buttonElement = (document.getElementsByName(
        'intercom-messenger-frame',
      )[0] as any)?.contentWindow.document.querySelector(
        '.intercom-composer-send-button',
      );

      if (buttonElement) {
        buttonElement.click();
      } else if (retriesCount <= 5) {
        // retry if the send button was not found (intercom was not loaded)
        retriesCount += 1;
        setTimeout(postMessage, 1500);
      }
    };

    setTimeout(postMessage, 600);
  }, [LS_KEY, initDone, message]);
};
